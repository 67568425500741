<template>
  <div class="title">
    <div class="left" @click="goBack">
      <img src="@/assets/cancel.png" alt />
    </div>
    <div class="right">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'headTitle',
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    returnPath: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  },
  created() {
    console.log(this.returnPath, 'returnPath');
  },
  mounted() {},
  methods: {
    goBack() {
      if (this.returnPath) {
        this.$router.push({
          name: this.returnPath
        });
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  background: rgba(255, 255, 255);
  display: flex;
  align-items: center;
  height: 44px;
  position: fixed;
  top: 0;
  z-index: 999;
  .left {
    height: 30px;
    margin-left: 13px;
    width: 15px;
    img {
      width: 15px;
      height: 25px;
    }
  }
  .right {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    color: #333333;
  }
}
</style>
